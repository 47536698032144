import { RefAttributes, forwardRef, HTMLProps } from "react";
import { LinkProps as NextLinkProps } from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
// import * as _addBasePath from "next/dist/client/add-base-path";
// import * as _addLocale from "next/dist/client/add-locale";

export type LinkProps = {
  activeClassName?: string;
  hard?: boolean;
} & RefAttributes<HTMLAnchorElement> &
  HTMLProps<HTMLAnchorElement> &
  NextLinkProps;

// Taken directly from Next.js source code
interface TransitionOptions {
  shallow?: boolean;
  locale?: string | false;
  scroll?: boolean;
}

export default forwardRef<
  HTMLAnchorElement,
  React.PropsWithChildren<LinkProps>
>(function Link(
  {
    activeClassName = "is-active",
    children,
    className,
    hard,
    href,
    locale,
    replace,
    scroll,
    shallow,
    ...props
  },
  ref
): React.ReactElement {
  const router = useRouter();
  // const addBasePath = _addBasePath.addBasePath;
  // const addLocale = _addLocale.addLocale;

  // Remove potential query string from current path and target href
  const asPath = router.asPath.replace(/\?.*?$/, "");
  const compareHref = href.replace(/\?.*?$/, "");
  const localeMatches = locale ? locale === router.locale : true;
  // const localeHref = addBasePath(
  //   addLocale(href, locale || router.locale, router?.defaultLocale)
  // );

  const transitionOptions: TransitionOptions = {
    locale,
    scroll,
    shallow,
  };

  return (
    // Why do we use a regular anchor instead of a next/link? Because we can't
    // disable the prefetch behavior completely with next/link. In the interest
    // of saving bandwidth, we want to do that.
    <a
      {...props}
      className={classNames(className, {
        [activeClassName]: localeMatches && asPath === compareHref,
      })}
      href={href}
      ref={ref}
      onClick={(event) => {
        props.onClick?.(event);
        event.preventDefault();

        if (href === '/gift' || hard) {
          window.location.href = href;
        }        

        if (replace) {
          router.replace(href, null, transitionOptions);
        } else {
          router.push(href, null, transitionOptions);
        }
      }}
    >
      {children}
    </a>
  );
});
