import { useState, useRef } from "react";
import Image, { ImageProps } from "next/image";
import classNames from "classnames";

const imageWidths = {
  "60": "mini",
  "204": "thumb",
  "384": "portrait",
  "748": "portrait",
  "782": "standard",
  "2000": "zoom",
};

function centraLoader({ src, width }) {
  let imageSize = imageWidths[width];

  if (!imageSize) { // fallback for strange issue
    imageSize = "portrait";
  }

  if (imageSize === "standard") {
    return src.replace(/-portrait\.(\w+)$/, `.$1`) + "?w=" + width;
  }

  // Include `width` in the returned string to prevent Next.js from complaining
  return src.replace(/-portrait\.(\w+)$/, `-${imageSize}.$1`) + "?w=" + width;
}

type Props = {
  alt?: string;
  className?: string;
  height?: number;
  onDimensionsLoad?(width: number, height: number): void;
  sizes?: string;
  src: string;
  width?: number;
} & Pick<ImageProps, "layout" | "priority">;

export default function CentraProductImage({
  alt = "",
  className = "",
  height: heightProp,
  layout = "responsive",
  onDimensionsLoad,
  priority = false,
  sizes,
  src,
  width: widthProp,
}: Props) {
  // Why do we use a ref to store `isLoaded` state instead of normal React
  // state? Because normal state can lead to "stale closures", which sometimes
  // causes very janky behavior where the onLoad function is triggered
  // endlessly even though `isLoaded` is supposed to be true
  const isLoadedRef = useRef<{ isLoaded: boolean }>();

  const centraImageSizes =
    typeof window !== "undefined" ? window.CENTRA_IMAGE_SIZES : {};
  const [width, setWidth] = useState(centraImageSizes[src]?.[0] || 748);
  const [height, setHeight] = useState(centraImageSizes[src]?.[1] || 995);

  function onLoad(naturalWidth: number, naturalHeight: number) {
    if (
      !isLoadedRef.current?.isLoaded &&
      naturalWidth > 1 &&
      naturalHeight > 1
    ) {
      isLoadedRef.current = { isLoaded: true };
      setWidth(naturalWidth);
      setHeight(naturalHeight);
      onDimensionsLoad?.(naturalWidth, naturalHeight);
    }
  }

  return (
    <Image
      className={classNames(className, {
        "is-loaded": priority || isLoadedRef.current?.isLoaded,
      })}
      src={src}
      sizes={sizes}
      alt={alt}
      loader={centraLoader}
      width={layout === "fill" ? null : widthProp || width}
      height={layout === "fill" ? null : heightProp || height}
      layout={layout}
      priority={priority}
      objectFit="cover"
      // onLoad isn't reliably triggered for images with priority=true, so we
      // listen to both `onLoad` and `onLoadingComplete`
      onLoad={(event) => {
        const { naturalWidth, naturalHeight } = event.currentTarget;
        onLoad(naturalWidth, naturalHeight);
      }}
      onLoadingComplete={({ naturalWidth, naturalHeight }) => {
        onLoad(naturalWidth, naturalHeight);
      }}
    />
  );
}
