import React, { PropsWithChildren, useContext } from "react";

import { getCanonicalProductCat } from "../lib/centra-api";
import { filterHref } from "../lib/utils";
import { StoryContext } from "../pages/[...route]";
import Link, { LinkProps } from "./Link";

export type Props = {
  category?: string;
  fallbackHref?: string;
  maybeForceCategory?: boolean;
  product: CentraProduct;
} & Omit<LinkProps, "href">;

export default function CentraProductLink({
  category,
  children,
  fallbackHref,
  maybeForceCategory,
  product,
  ...props
}: PropsWithChildren<Props>) {
  const context = useContext(StoryContext);
  let href = fallbackHref || product.uri;

  if (product) {
    const canonical = getCanonicalProductCat(product, category);

    if (!canonical && category && maybeForceCategory) {
      const forcedCategory = context.menuItems.find(
        (m) => m.content.centra?.id === category
      );

      if (forcedCategory) {
        href = `/${forcedCategory.full_slug}/${product.uri}`;
      }
    } else {
      href = `${canonical?.uri || ""}/${product.uri}`;
    }
  }

  if (context.redirects) {
    const foundDynamicRedirect = context.redirects.find(
      (r) => r.source === `/${href}`
    );
    if (foundDynamicRedirect) {
      href = foundDynamicRedirect.destination;
    }
  }

  return (
    <Link {...props} href={filterHref(href)}>
      {children}
    </Link>
  );
}
