import React, { useState, useContext, useEffect } from "react";
import { useRouter } from "next/router";
import Modal from "react-modal";
import classNames from "classnames";
import { useTranslations } from "next-intl";

import useSWR from "swr";
import { StoryContext } from "../pages/[...route]";
import { centraGet, centraPost } from "../lib/centra-api";
import { useCentraToken, voyadoIdentify } from "../lib/utils";

type Props = {
  closed: boolean;
  setClosed(val: boolean): void;
};

export default function NewsletterPopup({
  closed,
  setClosed,
}: Props): React.ReactElement {
  const router = useRouter();
  const context = useContext(StoryContext);
  const [token] = useCentraToken();
  const t = useTranslations("NewsletterPopup");
  const isWorkingInStoryblok = Boolean(router.query._storyblok);
  const { data: selectionData } = useSWR(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
    (uri) => centraGet(uri, token)
  );

  const [formStatus, setFormStatus] = useState("");
  const [inputIsActive, setInputIsActive] = useState(false);
  const [inputEmail, setInputEmail] = useState("");

  useEffect(() => {
    if (
      isWorkingInStoryblok &&
      router.query.route?.[0] === "global" &&
      router.query.route?.[1] === "popup"
    ) {
      setClosed?.(false);
    }
  }, [isWorkingInStoryblok]);

  async function registerNewsletter(event) {
    event.preventDefault();
    setFormStatus("submitting");

    try {
      const voyadoRes = await fetch("/api/voyado-register-newsletter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: inputEmail,
          language: router.locale,
          country: selectionData?.location.country
        }),
      });

      const voyadoData = await voyadoRes.json();

      voyadoIdentify(voyadoData.data.id);

      const data = await centraPost(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/newsletter-subscription/${inputEmail}`,
        token,
        { language: router.locale }
      );

      if (data.subscribed) {

        setFormStatus("success");
        setTimeout(() => setClosed(true), 2000);
      }
    } catch (error) {
      console.log("Error submitting popup newsletter form", error);
      setFormStatus("error");
    }
  }

  return (
    <Modal
      bodyOpenClassName="is-showingPopup"
      className="Popup-wrapper"
      isOpen={context.popup?.content.text.content && !closed}
      onRequestClose={() => setClosed(true)}
      style={{
        overlay: {
          backgroundColor: "var(--color-black-opacity)",
          overflow: "auto",
          zIndex: 105,
        },
      }}
    >
      <div className="Popup-modal">
        <button className="Popup-close" onClick={() => setClosed(true)}>
          <span>{t("closeButton")}</span>
          <i className="Icon Icon--clear" />
        </button>

        <div
          className="Popup-text u-richText"
          dangerouslySetInnerHTML={{
            __html: context.popup?.content.text.content,
          }}
        />

        <div className="Popup-formWrapper">
          <form
            className={classNames("ContactForm", {
              "is-submitting": formStatus === "submitting",
            })}
            onSubmit={registerNewsletter}
          >
            <label
              className={classNames("ContactForm-label", {
                "is-active": inputIsActive,
              })}
            >
              <span className="ContactForm-labelText">
                {t("form.inputLabel")}
              </span>

              <input
                className="ContactForm-input"
                name="email"
                required
                type="email"
                disabled={
                  formStatus === "submitting" || formStatus === "success"
                }
                onChange={(event) => setInputEmail(event.target.value)}
                onFocus={() => setInputIsActive(true)}
                onBlur={() => setInputIsActive(false)}
                value={inputEmail}
              />
            </label>

            <button
              className="ContactForm-button"
              disabled={formStatus === "submitting" || formStatus === "success"}
            >
              {formStatus === "submitting"
                ? t("form.submitting")
                : t("form.button")}
            </button>

            {formStatus === "success" && (
              <div className="ContactForm-response">{t("form.success")}</div>
            )}

            {formStatus === "error" && (
              <div className="ContactForm-response">{t("form.error")}</div>
            )}
          </form>
        </div>
      </div>
    </Modal>
  );
}
