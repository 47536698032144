import React, { useContext, useState } from "react";
import { useRouter } from "next/router";
import classNames from "classnames";
import { useTranslations } from "next-intl";
import Cookies from "js-cookie";

import useSWR, { useSWRConfig } from "swr";
import { StoryContext } from "../pages/[...route]";
import { centraGet, centraPost, centraPut } from "../lib/centra-api";
import { useCentraToken, voyadoIdentify } from "../lib/utils";
import Link from "./Link";
import Masonry from "./Masonry";

export default function Footer(): React.ReactElement {
  const router = useRouter();
  const context = useContext(StoryContext);
  const footer = context.footer;
  const [token] = useCentraToken();
  const t = useTranslations("Footer");
  const { data: selectionData } = useSWR(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
    (uri) => centraGet(uri, token)
  );

  const [formStatus, setFormStatus] = useState("");
  const [inputEmail, setInputEmail] = useState("");

  const { mutate } = useSWRConfig();

  const formElementsDisabled =
    formStatus === "submitting" || formStatus === "success";
  const year = new Date().getFullYear();
  let formButtonText = t("newsletterForm.button");

  if (formStatus === "submitting") {
    formButtonText = t("newsletterForm.working");
  } else if (formStatus === "success") {
    formButtonText = t("newsletterForm.success");
  }

  const setSelectionLanguage = async (lang) => {
    return await centraPut(`${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/languages/${lang}`, token);
  }

  async function registerNewsletter(event) {
    event.preventDefault();
    setFormStatus("submitting");

    try {
      const voyadoRes = await fetch("/api/voyado-register-newsletter", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: inputEmail,
          language: router.locale,
          country: selectionData?.location.country
        }),
      });

      const voyadoData = await voyadoRes.json()


      voyadoIdentify(voyadoData.data.id);




      const data = await centraPost(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/newsletter-subscription/${inputEmail}`,
        token,
        { language: router.locale }
      );

      if (data.subscribed) {
        setFormStatus("success");
      }
    } catch (error) {
      console.log("Error submitting footer newsletter form", error);
      setFormStatus("error");
    }
  }

  return (
    <footer className="Footer">
      <div className="Footer-leftColumn">
        <Masonry className="Footer-leftColumnGrid">
          <div className="Footer-linkList">
            <div
              className="Footer-text u-richTextNoUnderlines"
              dangerouslySetInnerHTML={{
                __html: footer.content.list_1?.content,
              }}
            />
          </div>

          <div className="Footer-linkList">
            <div
              className="Footer-text u-richTextNoUnderlines"
              dangerouslySetInnerHTML={{
                __html: footer.content.list_2?.content,
              }}
            />
          </div>

          <div className="Footer-linkList">
            <div
              className="Footer-text u-richTextNoUnderlines"
              dangerouslySetInnerHTML={{
                __html: footer.content.list_3?.content,
              }}
            />
          </div>
        </Masonry>

        <div className="Footer-userControls">
          <div className="Footer-langLinks">
            <Link
              href={router.asPath}
              locale="en"
              onClick={() => {
                Cookies.set("NEXT_LOCALE", "en");
                setSelectionLanguage("en").then(data => console.log(data))
              }}
            >
              EN
            </Link>
            {" / "}
            <Link
              href={router.asPath}
              locale="sv"
              onClick={() => {
                Cookies.set("NEXT_LOCALE", "sv");
                setSelectionLanguage("sv").then(data => console.log(data))
              }}
            >
              SV
            </Link>
          </div>
        </div>
      </div>

      <div className="Footer-centerColumn">
        <form
          className={classNames("Footer-newsletterForm", {
            "is-submitting": formStatus === "submitting",
          })}
          onSubmit={registerNewsletter}
        >
          <div className="Footer-text u-richTextNoUnderlines">
            <h5>{footer.content.newsletter_text}</h5>
          </div>

          <div className="Footer-newsletterControls">
            <label
              className="Footer-newsletterLabel"
              htmlFor="Footer-newsletterEmail"
            >
              {t("newsletterForm.inputLabel")}
            </label>

            <div className="Footer-newsletterInputWrapper">
              <input
                className="Footer-newsletterInput"
                id="Footer-newsletterEmail"
                name="email"
                placeholder="info@example.com"
                required
                type="email"
                disabled={formElementsDisabled}
                onChange={(event) => setInputEmail(event.currentTarget.value)}
                value={inputEmail}
              />
            </div>

            <button
              className="Footer-newsletterButton"
              disabled={formElementsDisabled}
            >
              {formButtonText}
            </button>
          </div>
        </form>

        <div className="Footer-logoWrapper">
          <div className="Footer-logo">
            <img
              src="/images/artilleriet-logo.svg"
              alt="Artilleriet"
              width="104"
              height="92"
              loading="lazy"
            />
          </div>

          <div className="Footer-text Footer-text--noMargin u-richTextNoUnderlines">
            <h5>© {year} - Artilleriet Interiors AB</h5>
          </div>
        </div>
      </div>

      <div className="Footer-rightColumn">
        <div className="Footer-contact">
          <div
            className="Footer-text u-richTextNoUnderlines"
            dangerouslySetInnerHTML={{
              __html: footer.content.contact_text?.content,
            }}
          />
        </div>
      </div>
    </footer>
  );
}
