import { MutableRefObject, useEffect, useState } from "react";
import fetchRetryModule from "@vercel/fetch-retry";
import { useRouter } from "next/router";
import createPersistedState from "use-persisted-state";
import Cookies from "js-cookie";
import { centraPostWithAuthAndCache } from "./centra-api";

export const fetchRetry = fetchRetryModule(fetch);
const fetchCache = new Map();

export async function fetchWithCache(uri: RequestInfo, args?: RequestInit) {
  const key = JSON.stringify([uri, args]);

  if (fetchCache.has(key)) {
    return fetchCache.get(key);
  }

  const res = await fetchRetry(uri, args);

  if (res.ok) {
    const data = await res.json();
    fetchCache.set(key, data);
    setTimeout(() => fetchCache.delete(key), 10 * 60 * 1000);

    return data;
  } else {
    console.error(res.status);
    console.error(res.statusText);
    console.error(res.url);
    console.error(args);
    throw new Error(`${res.status}`);
  }
}

export function filterHref(path: string): string {
  if (!path) {
    return "";
  } else if (path.startsWith("http")) {
    return path;
  } else if (path.startsWith("/")) {
    return path;
  }

  return "/" + path;
}

export function useBodyClass(className: string) {
  useEffect(() => {
    if (!className) {
      return;
    }

    const classNames = className.split(" ");
    classNames.map((c) => document.body.classList.add(c));

    return () => {
      classNames.map((c) => document.body.classList.remove(c));
    };
  }, [className]);
}

export function useMediaQuery(query: string): boolean {
  let defaultValue = false;

  if (typeof window !== "undefined") {
    const media = window.matchMedia(query);
    defaultValue = media.matches;
  }

  const [matches, setMatches] = useState(defaultValue);

  useEffect(() => {
    const media = window.matchMedia(query);

    if (media.matches !== matches) {
      setMatches(media.matches);
    }

    function listener() {
      setMatches(media.matches);
    }

    if (media.addEventListener) {
      media.addEventListener("change", listener);
    } else {
      media.addListener(listener);
    }

    return () => {
      if (media.removeEventListener) {
        media.removeEventListener("change", listener);
      } else {
        media.removeListener(listener);
      }
    };
  }, [matches, query]);

  return matches;
}

export function useEventListener(
  eventName: string,
  eventListener: (e: CustomEvent) => void,
  deps = []
) {
  useEffect(() => {
    window.addEventListener(eventName, eventListener);

    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, deps);
}

export function useRandomDoodle(): string {
  const doodles = [
    "/images/artilleriet_sale_doodles-01.svg",
    "/images/artilleriet_sale_doodles-02.svg",
    "/images/artilleriet_sale_doodles-03.svg",
    "/images/artilleriet_sale_doodles-04.svg",
    "/images/artilleriet_sale_doodles-05.svg",
    "/images/artilleriet_sale_doodles-06.svg",
    "/images/artilleriet_sale_doodles-07.svg",
    "/images/artilleriet_sale_doodles-08.svg",
    "/images/artilleriet_sale_doodles-09.svg",
  ];

  const [doodle, setDoodle] = useState("");

  useEffect(() => {
    const random = Math.floor(Math.random() * doodles.length);
    setDoodle(doodles[random]);
  }, []);

  return doodle;
}

export function useScrollIntoView(
  ref: MutableRefObject<HTMLElement>,
  selector: string
) {
  const router = useRouter();

  useEffect(() => {
    if (!ref.current) {
      return;
    }

    const target = ref.current.querySelector(selector);

    if (target) {
      target.scrollIntoView({
        block: "nearest",
        inline: "start",
      });
    }
  }, [ref.current, router.asPath]);
}

export function useAbortOnRouteChange() {
  if (typeof AbortController === "undefined") {
    return undefined;
  }

  const router = useRouter();
  const fetchController = new AbortController();

  useEffect(() => {
    function handleRouteChange() {
      fetchController.abort();
    }

    router.events.on("routeChangeStart", handleRouteChange);

    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
  });

  return fetchController;
}

const useTokenState = createPersistedState("centraSelectionToken");
const useTokenGiftState = createPersistedState("centraSelectionGiftToken");

export function useCentraToken() {
  const router = useRouter();
  const isGiftPage =
    router.route === "/gift" || router.route === "/gift/receipt";

  if (isGiftPage) {
    return useTokenGiftState("");
  }

  return useTokenState("");
}

export function useVoyadoContactId() {
  const id = Cookies.get("_vaI") || Cookies.get("_va");

  if (!id) {
    return null;
  }

  return id;
}

// Taken from https://usehooks.com/useDebounce/
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function serializeForm(form: HTMLFormElement): {
  [key: string]: string;
} {
  const data = {};

  for (const el of form.elements) {
    const element = el as HTMLInputElement;

    if (element.type === "checkbox") {
      if (element.name && element.checked) {
        data[element.name] = element.value;
      }
    } else if (element.name && element.value) {
      data[element.name] = element.value;
    }
  }

  return data;
}

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    va: any;
  }
}

export function voyadoIdentify(contactId: string) {
  if (!contactId) {
    return;
  }
  if (typeof window.va !== "function") {
    return;
  }

  // if customer is already identified we do not want to re-identify
  if (Cookies.get("_vaI")) {
    return;
  }

  window.va("setContactId", contactId);
}

type voyadoProduct = {
  itemId: string;
  quantity: number;
};

type voyadoCart = {
  cartRef: string;
  contactId: string;
  cartUrl?: string;
  locale: string;
  items: voyadoProduct[];
};

export function getVoyadoItems(selectionData) {
  const items = selectionData.selection.items.map((item: any) => {
    return {
      itemId: item.sku,
      quantity: item.quantity,
    };
  });

  return items;
}

export function voyadoCart(
  token,
  locale,
  items: Array<{ itemId: string; quantity: number }>
) {
  const contactId = useVoyadoContactId();
  if (!contactId) return;
  if (typeof window.va !== "function") {
    return;
  }

  if (typeof window.va === "undefined") return;

  const localeISO = locale === "sv" ? "sv-SE" : "en-US";

  window?.va("cart", {
    cartRef: token,
    contactId: contactId,
    locale: localeISO,
    items: items,
  });
}

export function voyadoEmptyCart(token: string) {
  if (!token) {
    return;
  }
  if (typeof window.va !== "function") {
    return;
  }

  window.va("emptyCart", { cartRef: token });
}

export const fetchCountOfDynamicPages = async () => {
  const productsData = await centraPostWithAuthAndCache(
    "https://artilleriet.centra.com/api/checkout/products",
    "none",
    {
      limit: 100,
      onlyAvailable: true,
      relatedProducts: false,
    }
  );

  return productsData.productCount * 2;
};

export const getProductSitemapUrls = async ({ page, pageSize }) => {
  let products: CentraProduct[] = [];
  let offset = page * pageSize;
  let progress = 0;
  const limit = 100;

  let moreProductsAvailable = true;

  while (moreProductsAvailable) {
    const productsData = await centraPostWithAuthAndCache(
      `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products`,
      "none",
      {
        limit: limit,
        skipFirst: offset,
        onlyAvailable: true,
        relatedProducts: false,
      }
    );

    if (!productsData) return;

    if (productsData.products.length > 0 && progress < pageSize) {
      products = products.concat(productsData.products);
      progress += limit;
      offset += limit;
    } else {
      moreProductsAvailable = false;
    }
  }

  const defaultproductSlugs = products.map((product) => {
    return {
      loc: `https://artilleriet.se/${product.uri}`,
      lastmod: new Date(product.modifiedAt).toISOString(),
      priority: 0.3,
    };
  });

  const svProductSlugs = products.map((product) => {
    return {
      loc: `https://artilleriet.se/sv/${product.uri}`,
      lastmod: new Date(product.modifiedAt).toISOString(),
      priority: 0.3,
    };
  });

  return [...defaultproductSlugs, ...svProductSlugs];
};

export const getStoryblokSitemapUrls = async () => {
  const sbBase = "https://api.storyblok.com/v2/cdn";
  const sbToken = "mMvOa74ZHyrpARBe1QPFQgtt";
  const allStories = [];

  let moreStoriesAvailable = true;
  let page = 0;
  let count = 0;

  while (moreStoriesAvailable && count < 10) {
    const url = new URL(`${sbBase}/stories/`);
    const search = new URLSearchParams({ version: "publish" });
    search.set("resolve_links", "story");
    search.set("per_page", "999");
    search.set("token", sbToken);
    search.set("page", String((page += 1)));
    url.search = search.toString();

    const res = await fetch(url);
    const data = await res.json();

    if (!data) return;

    if (data.stories.length > 0) {
      allStories.push(...data.stories);
      count++;
    } else {
      moreStoriesAvailable = false;
    }
  }

  const defaultSitemapItems = allStories.map((story) => {
    return {
      loc: `https://artilleriet.se/${story.full_slug.replace(/\/$/, "")}`,
      lastmod: new Date(story.published_at).toISOString(),
      changefreq: "daily",
      priority: 0.5,
    };
  });

  const svSitemapItems = allStories.map((story) => {
    return {
      loc: `https://artilleriet.se/sv/${story.full_slug.replace(/\/$/, "")}`,
      lastmod: new Date(story.published_at).toISOString(),
      changefreq: "daily",
      priority: 0.5,
    };
  });

  return [...defaultSitemapItems, ...svSitemapItems];
};

export const getBaseUrl = () => {
  return "https://artilleriet.se";
};
