import React, { useContext } from "react";

import { StoryContext } from "../pages/[...route]";
import Link from "../components/Link";

type Props = {
  _uid: string;
  cta: any;
  title: string;
};

export default function Depict({ cta, title }: Props): React.ReactElement {
  const context = useContext(StoryContext);

  let ctaLinkHref = cta?.cached_url ? "/" + cta.cached_url : "";

  if (cta?.linktype === "story") {
    const linkedStory = context.story.links?.find((s) => s.uuid === cta.id);

    if (linkedStory) {
      ctaLinkHref = "/" + linkedStory.full_slug;
    }
  }

  return (
    <>
      {title && (
        <div className="ProductGrid-header">
          <div className="ProductGrid-title">{title}</div>

          {ctaLinkHref ? (
            <Link className="ProductGrid-headerLink" href={ctaLinkHref}>
              View all
            </Link>
          ) : null}
        </div>
      )}

      <div id="depict_frontpage" />
    </>
  );
}
