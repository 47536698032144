import React, { PropsWithChildren, useEffect } from "react";
import { useRouter } from "next/router";
import createPersistedState from "use-persisted-state";

import NewsletterPopup from "./NewsletterPopup";
import LocalePopup from "./LocalePopup";
import Header from "./Header";
import Footer from "./Footer";
import Cart from "./Cart";

const useClosedLocalePopupState = createPersistedState("closedLocalePopup");
const useClosedNewsletterPopupState = createPersistedState(
  "closedNewsletterPopup"
);

export default function PageTemplate({
  children,
}: PropsWithChildren<unknown>): React.ReactElement {
  const router = useRouter();

  const [localePopupIsClosed, setLocalePopupIsClosed] =
    useClosedLocalePopupState(false);
  const [newsletterPopupIsClosed, setNewsletterPopupIsClosed] =
    useClosedNewsletterPopupState(false);

  // Attach client side navigation to links inside rich text wrappers
  useEffect(() => {
    function richTextLinkListener(event) {
      const target = event.target as HTMLAnchorElement;

      if (target.matches?.(".u-richText a, .u-richTextNoUnderlines a")) {
        if (event.defaultPrevented || target.origin !== location.origin) {
          return;
        }

        event.preventDefault();
        router.push(target.href);
      }
    }

    document.addEventListener("click", richTextLinkListener);

    return () => {
      document.removeEventListener("click", richTextLinkListener);
    };
  });

  return (
    <>
      <Header />

      <Cart />

      <main className="Main" id="main">
        {children}

        <NewsletterPopup
          closed={!localePopupIsClosed || newsletterPopupIsClosed}
          setClosed={setNewsletterPopupIsClosed}
        />

        <LocalePopup
          closed={localePopupIsClosed}
          setClosed={setLocalePopupIsClosed}
        />
      </main>

      {/* <CookieNotice /> */}

      <Footer />
    </>
  );
}
