import React, { useContext, useState } from "react";
import Modal from "react-modal";
import { useTranslations } from "next-intl";

import { StoryContext } from "../pages/[...route]";

type Props = {
  open?: boolean;
  setOpen?: React.Dispatch<boolean>;
};

export default function FaqPanel({ open, setOpen }: Props) {
  const context = useContext(StoryContext);
  const t = useTranslations("FaqPanel");

  const tabs = context.productsConfig?.content.faq_tabs || [];
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Modal
      bodyOpenClassName="is-showingPopup"
      className="Panel-wrapper"
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={{
        overlay: {
          backgroundColor: "var(--color-black-opacity)",
          overflow: "auto",
          zIndex: 105,
        },
      }}
    >
      <section className="Panel">
        <header className="Panel-header">
          <h4 className="Panel-heading">{t("heading")}</h4>

          <button
            className="Panel-closeButton"
            aria-label={t("closeButton")}
            onClick={() => setOpen(false)}
          >
            <i className="Icon Icon--clear" />
          </button>
        </header>

        <div className="Panel-tabsWrapper">
          <div
            className="Panel-tabs"
            style={{ "--tab-count": tabs.length } as React.CSSProperties}
          >
            {tabs.map((t, i) => (
              <details
                className="Panel-tab"
                open={i === activeTab}
                key={t._uid}
                style={{ "--tab-index": i } as React.CSSProperties}
              >
                <summary
                  className="Panel-tabSummary"
                  // Use an onClick event instead of onToggle to prevent
                  // a flash where two <details> are open at the same time
                  onClick={(event) => {
                    event.preventDefault();
                    setActiveTab(i);
                  }}
                >
                  <div>
                    <span>{t.label}</span>
                  </div>
                </summary>

                <div className="Panel-tabContent">
                  <div
                    className="u-richText"
                    dangerouslySetInnerHTML={{ __html: t.body.content }}
                  />
                </div>
              </details>
            ))}
          </div>
        </div>
      </section>
    </Modal>
  );
}
