import { createElement, PropsWithChildren, useEffect, useRef } from "react";
import dynamic from "next/dynamic";
import { Options as MasonryOptions } from "masonry-layout";

type Props = PropsWithChildren<{
  className?: string;
  elementType?: string;
  options?: MasonryOptions;
}>;

function MasonryWrapper(Masonry) {
  return function masonry({
    className,
    children,
    elementType = "div",
    options,
  }: Props) {
    const ref = useRef<HTMLDivElement>();

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const masonry = new Masonry(ref.current, {
        transitionDuration: 0,
        ...options,
      });

      ref.current?.classList.add("is-ready");

      return () => {
        masonry.destroy();
        ref.current?.classList.remove("is-ready");
      };
    }, [ref.current, options]);

    return createElement(elementType, { className, ref }, children);
  };
}

export default dynamic<Props>(
  () => import("masonry-layout").then((m) => MasonryWrapper(m.default)),
  { ssr: false }
);
