import React from "react";
import classNames from "classnames";

import { DynamicBlocks } from "./DynamicBlock";

type Props = {
  body: any;
  layout: "auto" | "alternative" | "unison";
  height: "predefined" | "content";
};

export default function Blocks({
  body,
  layout,
  height,
}: Props): React.ReactElement {
  return (
    <section
      className={classNames(`Blocks Blocks--${layout}`, {
        "Blocks--bordered": true,
        "Blocks--adaptToContent": height === "content",
        [`Blocks--${body.length}`]: body.length,
      })}
    >
      <DynamicBlocks blocks={body} layout={layout} />
    </section>
  );
}
