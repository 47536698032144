import classNames from "classnames";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import { useState } from "react";
import useSWR from "swr";
import { centraGet, centraPost } from "../lib/centra-api";
import { useCentraToken, voyadoIdentify } from "../lib/utils";

interface NewsletterFormProps {
    title: string,
    centered: boolean,
}

export default function NewsletterForm({ title, centered }: NewsletterFormProps) {
    const [token] = useCentraToken();
    const router = useRouter();
    const [formStatus, setFormStatus] = useState("");
    const [inputEmail, setInputEmail] = useState("");

    const { data: selectionData } = useSWR(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`,
        (uri) => centraGet(uri, token)
    );

    const t = useTranslations("Footer");

    async function registerNewsletter(event) {
        event.preventDefault();
        setFormStatus("submitting");

        try {
            const voyadoRes = await fetch("/api/voyado-register-newsletter", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({
                    email: inputEmail,
                    language: router.locale,
                    country: selectionData?.location.country
                }),
            });

            const voyadoData = await voyadoRes.json()


            voyadoIdentify(voyadoData.data.id);


            const data = await centraPost(
                `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/newsletter-subscription/${inputEmail}`,
                token,
                { language: router.locale }
            );

            if (data.subscribed) {
                setFormStatus("success");
            }
        } catch (error) {
            console.log("Error submitting footer newsletter form", error);
            setFormStatus("error");
        }
    }

    let formButtonText = t("newsletterForm.button");

    if (formStatus === "submitting") {
        formButtonText = t("newsletterForm.working");
    } else if (formStatus === "success") {
        formButtonText = t("newsletterForm.success");
    }

    const formElementsDisabled =
        formStatus === "submitting" || formStatus === "success";

    /* Using footer classes thoughout as component will look the same */
    return (
        <form
            className={classNames("Footer-newsletterForm", {
                "is-submitting": formStatus === "submitting",
                "--centered": centered,
            })}
            onSubmit={registerNewsletter}
        >
            <div className="Footer-text u-richTextNoUnderlines">
                <h5>{title}</h5>
            </div>

            <div className="Footer-newsletterControls">
                <label
                    className="Footer-newsletterLabel"
                    htmlFor="Footer-newsletterEmail"
                >
                    {t("newsletterForm.inputLabel")}
                </label>

                <div className="Footer-newsletterInputWrapper">
                    <input
                        className="Footer-newsletterInput"
                        id="Footer-newsletterEmail"
                        name="email"
                        placeholder="info@example.com"
                        required
                        type="email"
                        disabled={formElementsDisabled}
                        onChange={(event) => setInputEmail(event.currentTarget.value)}
                        value={inputEmail}
                    />
                </div>

                <button
                    className="Footer-newsletterButton"
                    disabled={formElementsDisabled}
                >
                    {formButtonText}
                </button>
            </div>
        </form>
    )
}