import React, { useContext } from "react";
import SbEditable from "storyblok-react";

import NewsletterForm from "../components/NewsletterForm";
import ContactForm from "../components/ContactForm";
import Link from "../components/Link";
import { StoryContext } from "../pages/[...route]";
import StoryblokImage from "../components/StoryblokImage";

type Props = {
  _uid: string;
};

export default function PageCustomerService({
  _uid,
}: Props): React.ReactElement {
  const context = useContext(StoryContext);

  const story = context.story;
  const linkGroups = context.mainMenu?.content.customer_service_links;

  function resolveLink(block) {
    if (block.link.linktype === "story") {
      const resolvedLink = context.mainMenu.links?.find(
        (ll) => ll.uuid === block.link.id
      );

      if (resolvedLink) {
        block.link.story = resolvedLink;
      } else {
        block.link.story = { name: "Save to view" };
      }
    }

    return block;
  }

  return (
    <section className="CustomerService">
      <div className="CustomerService-nav">
        {linkGroups?.map((b) => (
          <ul className="CustomerService-navGroup" key={b._uid}>
            <li className="CustomerService-navItem">
              <h5 className="CustomerService-navHeading">{b.text}</h5>
            </li>

            {b.links?.map((l) => {
              const block = resolveLink(l);

              return (
                <li className="CustomerService-navItem" key={block._uid}>
                  <Link
                    href={
                      block.link.story?.full_slug
                        ? `/${block.link.story.full_slug}`
                        : block.link.url
                    }
                  >
                    {block.text || block.link.story?.name}
                  </Link>
                </li>
              );
            })}
          </ul>
        ))}
      </div>

      <div className="CustomerService-body">
        {story.content.body?.map((b) => {
          if (b.component === "block-customer-service") {
            return (
              <SbEditable content={b} key={b._uid}>
                <div className="CustomerService-block">
                  <div
                    className="u-richText"
                    dangerouslySetInnerHTML={{ __html: b.text.content }}
                  />
                </div>
              </SbEditable>
            );
          }

          if (b.component === "image-customer-service") {
            return (
              <SbEditable content={b} key={b._uid}>
                <div className="CustomerService-block">
                  <StoryblokImage
                    asset={b.image}
                    sizes="(min-width: 1024px) 50vw, 100vw"
                  />
                </div>
              </SbEditable>
            );
          }

          if (b.component === "embed-customer-service") {
            return (
              <SbEditable content={b} key={b._uid}>
                <div className="CustomerService-block">
                  <div dangerouslySetInnerHTML={{ __html: b.html }} />
                </div>
              </SbEditable>
            );
          }

          if (b.component === "form-customer-service") {
            return (
              <SbEditable content={b} key={b._uid}>
                <div className="CustomerService-block CustomerService-block--form">
                  <ContactForm additionalData={{ subject: b.subject }} />
                </div>
              </SbEditable>
            );
          }

          if (b.component === "form-newsletter") {
            return (
              <SbEditable content={b} key={b._uid}>
                <div className="Blocks-newsletter">
                  <NewsletterForm
                    centered={b.centered}
                    title={b.title}
                  />
                </div>
              </SbEditable>
            );
          }
        })}
      </div>
    </section>
  );
}
