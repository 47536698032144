import React, { useContext, useEffect, useRef } from "react";
import pickBy from "lodash/pickBy";

import { StoryContext } from "../pages/[...route]";
import Link from "../components/Link";
import {
  getAllStoryblokStories,
  getStoryblokStory,
} from "../lib/storyblok-api";
import { useScrollIntoView } from "../lib/utils";
import { BlockQuery, DynamicBlocks } from "./DynamicBlock";

type Props = {
  _uid: string;
};

export default function PageGeneric({ _uid }: Props): React.ReactElement {
  const context = useContext(StoryContext);
  const queryData = context.queries[_uid];
  const story = context.story;

  const breadcrumbsRef = useRef<HTMLDivElement>();

  const parents = queryData.parents || [];
  let breadcrumbs = [];

  if (queryData.children?.length) {
    breadcrumbs = queryData.children;
  } else if (queryData.siblings?.length) {
    breadcrumbs = queryData.siblings;
  }

  // This code is originally taken from https://ericeastwood.com/blog/
  useEffect(() => {
    if (!breadcrumbsRef.current) {
      return;
    }

    let latestScrollTop = 0;
    let lastReactedScrollTop = 0;
    let builtUpScrollTop = 0;
    let refHeight = 0;
    let refTranslateY = 0;
    let animationFrameId: number;

    function transformElement() {
      if (!breadcrumbsRef.current) {
        return;
      }

      builtUpScrollTop += latestScrollTop - lastReactedScrollTop;
      refHeight = breadcrumbsRef.current.offsetHeight;
      refTranslateY = clamp(-refHeight, 0, refTranslateY - builtUpScrollTop);

      breadcrumbsRef.current.style.transform = `translateY(${refTranslateY}px)`;

      builtUpScrollTop = 0;
      lastReactedScrollTop = latestScrollTop;
    }

    function clamp(min, max, value) {
      return Math.min(Math.max(value, min), max);
    }

    function handleScroll() {
      latestScrollTop = Math.max(0, window.scrollY);
      animationFrameId = requestAnimationFrame(transformElement);
    }

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      cancelAnimationFrame(animationFrameId);
    };
  }, [breadcrumbsRef.current]);

  const breadcrumbsContentRef = useRef<HTMLUListElement>();
  useScrollIntoView(breadcrumbsContentRef, ".Breadcrumbs-item a.is-active");

  return (
    <div>
      {story.content.show_breadcrumbs ? (
        <div className="Breadcrumbs" ref={breadcrumbsRef}>
          <ul className="Breadcrumbs-content" ref={breadcrumbsContentRef}>
            {!parents.length ? (
              <li className="Breadcrumbs-item Breadcrumbs-item--bold">
                <span>{story.content.breadcrumbs_heading}</span>
              </li>
            ) : null}

            {parents.map((b, i) => (
              <React.Fragment key={b.full_slug}>
                {i > 0 ? <i className="Icon Icon--arrowRight" /> : null}
                <li className="Breadcrumbs-item Breadcrumbs-item--bold">
                  <Link href={"/" + b.full_slug}>
                    {b.content.name || b.name}
                  </Link>
                </li>
              </React.Fragment>
            ))}

            <div className="Breadcrumbs-separator" />

            {breadcrumbs.map((b) => (
              <li
                className="Breadcrumbs-item Breadcrumbs-item--subnav"
                key={b.full_slug}
              >
                <Link href={"/" + b.full_slug}>{b.content.name || b.name}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}

      <DynamicBlocks blocks={story?.content.body} />
    </div>
  );
}

export const query: BlockQuery<void> = async (
  props,
  story,
  locale,
  isWorkingInStoryblok
) => {
  const slug = story.full_slug;
  const slugParts = slug.split("/");
  const parentSlugs = slugParts.slice(0, -1);
  const siblingsStartsWith =
    slugParts.length > 1 ? slugParts.slice(0, -1) : slugParts.slice(0);

  const sbParams = {
    version: isWorkingInStoryblok ? "draft" : "published",
    language: locale === "sv" ? "sv" : "",
    "filter_query[component][in]": "page",
  };

  const parentsRes = Promise.all(
    parentSlugs.map((part, i) => {
      const slug = parentSlugs.slice(0, i + 1);
      return getStoryblokStory(slug.join("/"), sbParams);
    })
  ).catch((err) => {
    console.log(err);
  });

  const childrenRes = getAllStoryblokStories({
    ...sbParams,
    sort_by: "name:asc",
    starts_with: slugParts.join("/"),
    excluding_ids: story.id,
  });

  const siblingsRes = getAllStoryblokStories({
    ...sbParams,
    sort_by: "name:asc",
    starts_with: siblingsStartsWith.join("/"),
  });

  const [parents, children, siblings] = await Promise.all([
    parentsRes,
    childrenRes,
    siblingsRes,
  ]);

  return pickBy({
    parents,
    children,
    siblings,
  });
};
