import React, { useContext } from "react";
import Modal from "react-modal";
import { useTranslations } from "next-intl";

import { StoryContext } from "../pages/[...route]";
import ContactForm from "./ContactForm";

type Props = {
  open?: boolean;
  setOpen?: React.Dispatch<boolean>;
  additionalData?: {
    [key: string]: string;
  };
};

export default function CustomerServicePopup({
  open,
  setOpen,
  additionalData,
}: Props): React.ReactElement {
  const context = useContext(StoryContext);
  const t = useTranslations("ContactForm");

  return (
    <Modal
      bodyOpenClassName="is-showingPopup"
      className="Popup-wrapper"
      isOpen={open}
      onRequestClose={() => setOpen(false)}
      style={{
        overlay: {
          backgroundColor: "var(--color-black-opacity)",
          overflow: "auto",
          zIndex: 105,
        },
      }}
    >
      <div className="Popup-modal">
        <button className="Popup-close" onClick={() => setOpen(false)}>
          <span>{t("closeButton")}</span>
          <i className="Icon Icon--clear" />
        </button>

        <div
          className="Popup-text u-richText"
          dangerouslySetInnerHTML={{
            __html: context.productsConfig?.content.order_from_cs_text.content,
          }}
        />

        <div className="Popup-formWrapper">
          <ContactForm hideSubject additionalData={additionalData} />
        </div>
      </div>
    </Modal>
  );
}
