import React, { useState } from "react";
import classNames from "classnames";
import useSWR from "swr";
import { useTranslations } from "next-intl";

import { centraGet } from "../lib/centra-api";
import { useCentraToken, serializeForm } from "../lib/utils";

type Props = {
  additionalData?: {
    [key: string]: string;
  };
  hideSubject?: boolean;
  forKitchenStudio?: boolean;
};

export default function CustomerServiceForm({
  additionalData,
  hideSubject,
  forKitchenStudio,
}: Props) {
  const [token] = useCentraToken();
  const t = useTranslations("ContactForm");

  // Translated ticket types. Add in translation files before adding here
  let ticketTypes = [
    "subjects.purchasingAssistance",
    "subjects.productQuestions",
    "subjects.serviceDeliveries",
    "subjects.kitchenStudio",
    "subjects.designStudio",
    "subjects.returnsClaims",
    "subjects.furnitureOrders",
    "subjects.otherQuestions",
    "subjects.b2b"
  ];

  if (forKitchenStudio) {
    ticketTypes = ["subjects.kitchenStudio", "subjects.designStudio", "subjects.b2b"];
  }

  const [activeInput, setActiveInput] = useState("");
  const [formStatus, setFormStatus] = useState("");
  const [ticketType, setTicketType] = useState(t(ticketTypes[0]));

  const cartUrl = `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/selection`;
  const { data: selectionData } = useSWR(cartUrl, (uri) =>
    centraGet(uri, token)
  );

  let defaultName = "";
  let defaultEmail = "";
  let defaultPhone = "";

  if (selectionData?.selection?.shippingAddress?.firstName) {
    defaultName = selectionData.selection.shippingAddress.firstName;

    if (selectionData?.selection?.shippingAddress?.lastName) {
      defaultName += " " + selectionData.selection.shippingAddress.lastName;
    }
  }

  if (selectionData?.selection?.shippingAddress?.email) {
    defaultEmail = selectionData.selection.shippingAddress.email;
  }

  if (selectionData?.selection?.shippingAddress?.phoneNumber) {
    defaultPhone = selectionData.selection.shippingAddress.phoneNumber;
  }

  const formElementsDisabled =
    formStatus === "submitting" || formStatus === "success";

  async function submitForm(event) {
    event.preventDefault();
    setFormStatus("submitting");

    try {
      const formData = serializeForm(event.currentTarget);
      Object.assign(formData, additionalData);

      const res = await fetch("/api/contact-customer-service", {
        method: "POST",
        body: JSON.stringify(formData),
        headers: {
          "content-type": "application/json; charset=UTF-8",
        },
      });

      if (!res.ok) {
        throw Error(res.statusText);
      }

      const data = await res.json();

      if (data?.status === "success") {
        setFormStatus("success");
      }
    } catch (error) {
      console.log("Error submitting customer service form", error);
      setFormStatus("error");
    }
  }

  return (
    <form
      className={classNames("ContactForm", {
        "is-submitting": formStatus === "submitting",
        "is-submitted": formStatus === "success",
      })}
      action="/api/send-email"
      method="POST"
      onSubmit={submitForm}
    >
      {hideSubject ? (
        <label className="ContactForm-label">
          <span className="ContactForm-labelText">{t("labels.subject")}</span>

          <input
            className="ContactForm-input"
            name="ticket_type"
            value={additionalData.productName}
          />
        </label>
      ) : (
        <label className="ContactForm-label">
          <span className="ContactForm-labelText">{t("labels.subject")}</span>

          <div className="ContactForm-fauxSelect">
            <span>{ticketType}</span>
            <i className="Icon Icon--arrowDown" />
          </div>

          <select
            className="ContactForm-select"
            name="ticket_type"
            onChange={(event) => setTicketType(event.target.value)}
            value={ticketType}
          >
            {ticketTypes.map((type, i) => (
              <option key={i}>{t(type)}</option>
            ))}
          </select>
        </label>
      )}

      <label
        className={classNames("ContactForm-label ContactForm-label--half", {
          "is-active": activeInput === "name",
        })}
      >
        <span className="ContactForm-labelText">{t("labels.name")} *</span>

        <input
          className="ContactForm-input"
          name="name"
          required
          autoComplete="name"
          defaultValue={defaultName}
          disabled={formElementsDisabled}
          onFocus={() => setActiveInput("name")}
          onBlur={() => setActiveInput("")}
        />
      </label>

      <label
        className={classNames("ContactForm-label ContactForm-label--half", {
          "is-active": activeInput === "company",
        })}
      >
        <span className="ContactForm-labelText">{t("labels.company")}</span>

        <input
          className="ContactForm-input"
          name="company"
          autoComplete="organization"
          disabled={formElementsDisabled}
          onFocus={() => setActiveInput("company")}
          onBlur={() => setActiveInput("")}
        />
      </label>

      <label
        className={classNames("ContactForm-label ContactForm-label--half", {
          "is-active": activeInput === "email",
        })}
      >
        <span className="ContactForm-labelText">{t("labels.email")} *</span>

        <input
          className="ContactForm-input"
          name="email"
          required
          type="email"
          autoComplete="email"
          defaultValue={defaultEmail}
          disabled={formElementsDisabled}
          onFocus={() => setActiveInput("email")}
          onBlur={() => setActiveInput("")}
        />
      </label>

      <label
        className={classNames("ContactForm-label ContactForm-label--half", {
          "is-active": activeInput === "telephone",
        })}
      >
        <span className="ContactForm-labelText">{t("labels.tel")}</span>

        <input
          className="ContactForm-input"
          name="telephone"
          type="tel"
          autoComplete="tel"
          defaultValue={defaultPhone}
          disabled={formElementsDisabled}
          onFocus={() => setActiveInput("telephone")}
          onBlur={() => setActiveInput("")}
        />
      </label>

      <label
        className={classNames("ContactForm-label", {
          "is-active": activeInput === "message",
        })}
      >
        <span className="ContactForm-labelText">{t("labels.message")} *</span>

        <textarea
          className="ContactForm-textarea"
          name="message"
          required
          disabled={formElementsDisabled}
          onFocus={() => setActiveInput("message")}
          onBlur={() => setActiveInput("")}
        />
      </label>

      <button className="ContactForm-button" disabled={formElementsDisabled}>
        {formStatus === "submitting" ? t("formSubmitting") : t("formButton")}
      </button>

      {formStatus === "success" ? (
        <div className="ContactForm-response">{t("formSuccess")}</div>
      ) : null}

      {formStatus === "error" ? (
        <div className="ContactForm-response">{t("formError")}</div>
      ) : null}
    </form>
  );
}
