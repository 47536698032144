import React from "react";
import Modal from "react-modal";
import { useTranslations } from "next-intl";
import { useRouter } from "next/router";
import Cookies from "js-cookie";

import { centraPut } from "../lib/centra-api";
import { useCentraToken } from "../lib/utils";
import Link from "./Link";

type Props = {
  closed: boolean;
  setClosed(val: boolean): void;
};

export default function LocalePopup({
  closed,
  setClosed,
}: Props): React.ReactElement {
  const t = useTranslations("CurrencyPopup");
  const router = useRouter();
  const [token] = useCentraToken();


  function closePopup() {
    setClosed?.(true);
  }

  const setSelectionLanguage = async (lang) => {
    return await centraPut(`${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/languages/${lang}`, token);
  }

  return (
    <Modal
      bodyOpenClassName="is-showingPopup"
      className="Popup-wrapper"
      isOpen={!closed}
      onRequestClose={() => closePopup()}
      style={{
        overlay: {
          backgroundColor: "var(--color-black-opacity)",
          overflow: "auto",
          zIndex: 105,
        },
      }}
    >
      <div className="Popup-modal Popup-modal--locale">
        <button className="Popup-close" onClick={() => closePopup()}>
          <span>{t("closeButton")}</span>
          <i className="Icon Icon--clear" />
        </button>

        <div className="Popup-text">
          <p>
            It looks like you’re visiting for the first time. Please select a
            language below.
          </p>
        </div>

        <div className="Popup-buttons">
          <Link
            className="Popup-button"
            href={router.asPath}
            locale="en"
            onClick={() => {
              Cookies.set("NEXT_LOCALE", "en");
              setSelectionLanguage("en");
              closePopup();
            }}
          >
            <img src="/images/globe-flag.svg" alt="" />
            <span>English</span>
          </Link>

          <Link
            className="Popup-button"
            href={router.asPath}
            locale="sv"
            onClick={() => {
              Cookies.set("NEXT_LOCALE", "sv");
              setSelectionLanguage("sv");
              closePopup();
            }}
          >
            <img src="/images/sweden-flag.svg" alt="" />
            <span>Svenska</span>
          </Link>
        </div>
      </div>
    </Modal>
  );
}
