/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useState } from "react";
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from "next";
import Script from "next/script";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import { useSWRConfig } from "swr";
import { ISbStoryData } from "storyblok-js-client";
import pickBy from "lodash/pickBy";

import classNames from "classnames";
import {
  getAllStoryblokStories,
  getStoryblokStory,
  getGlobalPageData,
  fetchBlockQueries,
  fetchRedirects,
} from "../lib/storyblok-api";
import {
  centraPost,
  centraPostWithAuthAndCache,
  fetchProductData,
  getCanonicalProductCat,
} from "../lib/centra-api";
import { DynamicBlock } from "../blocks/DynamicBlock";
import PageTemplate from "../components/PageTemplate";
import PageProduct from "../components/PageProduct";

type StoryDataWithLinks = {
  links: ISbStoryData[];
} & ISbStoryData;

type RedirectsLinks = {
  statusCode: number;
  destination: string;
  source: string;
}

/* eslint-disable @typescript-eslint/no-explicit-any */
type StoryProps = {
  brandDescription?: any;
  isWorkingInStoryblok?: boolean;
  footer?: ISbStoryData;
  mainMenu?: StoryDataWithLinks;
  menuItems?: ISbStoryData[];
  notices?: ISbStoryData;
  popup?: ISbStoryData;
  productId?: string;
  productsConfig?: ISbStoryData;
  queries?: {
    [key: string]: any;
  };
  redirects?: RedirectsLinks[];
  route?: string[];
  story?: StoryDataWithLinks;
  swrFallback?: {
    [key: string]: any;
  };
};

declare global {
  interface Window {
    StoryblokBridge: any;
  }
}

export const StoryContext = createContext<StoryProps | null>(null);

export const StoryProvider = ({
  children,
  ...props
}: React.PropsWithChildren<StoryProps>): React.ReactElement => {
  return (
    <StoryContext.Provider value={props}>{children}</StoryContext.Provider>
  );
};

const resolveRelations = ["block.source", "journal-block.source"];

export default function Route(
  props: InferGetStaticPropsType<typeof getStaticProps>
): React.ReactElement {
  const router = useRouter();
  const { cache } = useSWRConfig();
  const [previewStory, setPreviewStory] = useState<ISbStoryData>();
  const [previewPopup, setPreviewPopup] = useState<ISbStoryData>();
  const [previewMainMenu, setPreviewMainMenu] = useState<StoryDataWithLinks>();
  const [previewProductsConfig, setPreviewProductsConfig] =
    useState<ISbStoryData>();

  const story = previewStory || props.story;
  const popup = previewPopup || props.popup;
  const mainMenu = previewMainMenu || props.mainMenu;
  const productsConfig = previewProductsConfig || props.productsConfig;

  // In-app navigation to product pages is always "shallow". This means that
  // server side data fetching isn't executed in those cases. This is why
  // we check the client-side cache of swr to find the active product
  let asPath = router.asPath;
  if (asPath.indexOf("?") !== -1 && !router.query?._storyblok) {
    asPath = asPath.substring(0, asPath.indexOf("?"));
  }

  const slug = asPath.replace(/\/page\/\d+$/, "").replace(/.*\/([^/]+)$/, "$1");

  const products = Object.values(props.swrFallback) as CentraProductApiData[];

  for (const [key, value] of (cache as Map<string, any>).entries()) {
    if (key.startsWith("centraproduct://")) {
      products.push(value);
    }
  }

  const activeProduct = products.find((p) => p.product.uri === slug);
  const productId = activeProduct?.product.product;

  function initStoryblokBridge() {
    const storyblokInstance = new window.StoryblokBridge({
      resolveRelations,
    });

    storyblokInstance.on(["change", "published"], () => {
      location.reload();
    });

    storyblokInstance.on("input", (event) => {
      if (event.story.id === props.story?.id) {
        setPreviewStory(event.story);
      } else if (event.story.id === props.popup?.id) {
        setPreviewPopup(event.story);
      } else if (event.story.id === props.mainMenu?.id) {
        setPreviewMainMenu({ ...event.story, links: props.mainMenu.links });
      } else if (event.story.id === props.productsConfig?.id) {
        setPreviewProductsConfig(event.story);
      }
    });
  }

  if (productId) {
    return (
      <StoryProvider {...props} productsConfig={productsConfig}>
        <PageTemplate>
          <PageProduct key={productId + router.locale} productId={productId} />
        </PageTemplate>

        {props.isWorkingInStoryblok && (
          <Script
            src="//app.storyblok.com/f/storyblok-v2-latest.js"
            onLoad={initStoryblokBridge}
          />
        )}
      </StoryProvider>
    );
  }
  
  const ogImages = story?.content.og_image?.map(element => {
    const url = new URL(element.filename);
    url.hostname = "img2.storyblok.com";
    url.pathname = `1200x0${url.pathname}`;
    return {url: url.toString(), alt: element.name}
  }) || false;
  let openGraph = {
    type: 'website',
    title: `${story?.content.name || story?.name} – Artilleriet`,
  }
  if (ogImages) {
    openGraph = Object.assign(openGraph, {
      images: ogImages
    })
  }

  const bgColor = props.notices?.content.bg_color || "black";

  return (
    <StoryProvider {...props} story={story} popup={popup} mainMenu={mainMenu}>
      <NextSeo openGraph={openGraph} />

      <PageTemplate>
        {props.notices?.content.top.content && (
          <div
            className={classNames("Header-notification u-richText", {
              "--bg-black": bgColor === "black",
            })}
            {...(bgColor &&
              bgColor !== "black" && {
                style: {
                  backgroundColor: bgColor,
                },
              })}
            dangerouslySetInnerHTML={{
              __html: props.notices?.content.top.content.repeat(10),
            }}
          />
        )}

        <DynamicBlock block={story?.content} />
      </PageTemplate>

      {props.isWorkingInStoryblok && (
        <Script
          src="//app.storyblok.com/f/storyblok-v2-latest.js"
          onLoad={initStoryblokBridge}
        />
      )}
    </StoryProvider>
  );
}

export const getStaticProps: GetStaticProps = async ({
  params,
  locale,
  preview = false,
}) => {
  const isWorkingInStoryblok = process.env.NODE_ENV !== "production" || preview;
  const messages = await import(`../messages/${locale}.json`);

  const route = (params?.route as string[]) || ["home"];
  let uri = route.join("/");

  if (/^global\//.test(uri)) {
    uri = "home";
  }

  if (/\/page\/\d+$/.test(route.join("/"))) {
    uri = route.slice(0, -2).join("/");
  }

  // check if dynamic redirect exists
  const redirects = await fetchRedirects();
  const foundDynamicRedirect = redirects.find((r) => r.source === `/${uri}`);

  if (
    foundDynamicRedirect &&
    process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD
  ) {
    return {
      redirect: {
        source: foundDynamicRedirect.source,
        destination: foundDynamicRedirect.destination,
        permanent: true,
      },
    };
  }

  const sbParams = {
    version: isWorkingInStoryblok ? "draft" : "published",
    resolve_relations: resolveRelations.join(","),
    language: locale === "sv" ? "sv" : "",
  };

  const storyRes = getStoryblokStory(uri, sbParams).catch(() => {
    // Do nothing
  });

  const centraRes = centraPostWithAuthAndCache(
    `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products`,
    "none",
    {
      uri: {
        uri,
        for: ["product"],
      },
      language: locale,
      limit: 1,
      relatedProducts: true,
    }
  ).catch(() => {
    // Do nothing
  });

  const [story, centraData] = await Promise.all([storyRes, centraRes]);

  if (!story && !centraData?.productCount) {
    // If we couldn't find a product at the requested URL, we attempt to look
    // up just the last part of the URL (i.e. the slug). Why? Because product
    // URL's are based on their respective categories, and if a product is
    // removed from a category, that URL shouldn't break. So this behavior is
    // basically a failsafe for handling outdated product URL's.
    if (route.length > 1) {
      const productSlug = route.slice(-1)[0];
      const secondCentraData = await centraPost(
        `${process.env.NEXT_PUBLIC_CENTRA_BASE_URL}/api/checkout/products`,
        "none",
        {
          uri: {
            uri: productSlug,
            for: ["product"],
          },
          language: locale,
          limit: 1,
          relatedProducts: true,
        }
      ).catch(() => {
        // Do nothing
      });

      if (secondCentraData?.products?.length) {
        const product = secondCentraData?.products?.[0];
        const canonical = getCanonicalProductCat(product);
        const canonicalUri = canonical?.uri ? canonical?.uri + "/" : "";
        const productUrl = `/${canonicalUri}${product.uri}`;

        if (process.env.NODE_ENV !== "production") {
          console.log(
            `Found another canonical uri for product "${product.uri}" - "${productUrl}"`
          );
        }

        return {
          redirect: {
            destination: productUrl,
            permanent: false,
          },
        };
      }
    }

    if (process.env.NODE_ENV !== "production") {
      console.log("Could not find page at path", uri);
    }

    return {
      notFound: true,
    };
  }

  let pageDataPromise;

  if (story) {
    if (!story.content) {
      return {
        notFound: true,
      };
    }
    if (story.content.component === "category") {
      const result = /\/page\/(\d+)$/.exec(route.join("/"));

      if (result?.[1]) {
        story.content.page = result[1];
      }
    }

    pageDataPromise = fetchBlockQueries(
      story,
      resolveRelations,
      locale,
      isWorkingInStoryblok
    );
  } else if (centraData?.products?.[0]) {
    pageDataPromise = fetchProductData(centraData.products[0], locale);
  }

  const globalDataPromise = getGlobalPageData(
    sbParams.language,
    sbParams.version
  );

  try {
    const [globalData, pageData] = await Promise.all([
      globalDataPromise,
      pageDataPromise,
    ]);

    const props = {
      ...globalData,
      ...pageData,
      redirects: redirects,
      isWorkingInStoryblok,
      messages: messages.default,
    };

    if (story) {
      props.story = story;
    } else if (centraData?.products?.[0]) {
      const product = centraData?.products?.[0];
      props.productId = product?.product;
    }

    return {
      revalidate: 60 * 10,
      props: pickBy(props),
    };
  } catch (error) {
    console.log(error);
    return {
      notFound: true,
    };
  }
};

export const getStaticPaths: GetStaticPaths = async () => {
  const stories = await getAllStoryblokStories({
    "filter_query[component][in]": "category",
    // sort_by: "content.sort_no:desc",
    version: "published",
  });

  const paths = stories
    .filter((s) => s.full_slug !== "/" && s.full_slug !== "home")
    .map((s) => {
      const splitSlug = s.full_slug.split("/").filter(Boolean);

      return {
        params: { route: splitSlug },
      };
    });

  return {
    paths,
    fallback: "blocking",
  };
};
